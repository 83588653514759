import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, takeUntil, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgreeJurPersonObj, AgreeNatPersonObj, RoleCall } from '../models/agree';
import { Wirtschaftzweig } from '../types/mappingEvaDifa';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class Agree21Service {
  PERSONEN_NUMMER;
  IBAN;
  agreeNatPersonObj = new AgreeNatPersonObj();
  agreeJurPersonObj = new AgreeJurPersonObj();

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.IBAN = this.auth.user?.iban;
    this.PERSONEN_NUMMER = this.auth.user?.kundenNummer;
  }

  public postNatPersonKontokorrent(creditLimitAmount: number): Observable<any> {
    const response = this.http.post(`${environment.apiURL}/agree21api/natPersonKontokorrent`, {
      creditLimitAmount: creditLimitAmount,
      steuerID: '',
      env: environment.name,
      _id: this.auth.user._id,
    });
    return response;
  }

  public postNatPersonen(creditLimitAmount: number, isGbr: boolean): Observable<any> {
    const response = this.http.post(`${environment.apiURL}/agree21api/createNatPersonen`, {
      creditLimitAmount: creditLimitAmount,
      steuerID: '',
      _id: this.auth.user._id,
      env: environment.name,
      isGbr: isGbr,
    });
    return response;
  }

  public postJurPerson(creditLimitAmount: number): Observable<any> {
    const response = this.http.post(`${environment.apiURL}/agree21api/createJurPerson`, {
      env: environment.name,
      creditLimitAmount: creditLimitAmount,
      steuerID: '',
      _id: this.auth.user._id,
    });
    return response;
  }

  public postGbRPerson(creditLimitAmount: number): Observable<any> {
    const response = this.http.post(`${environment.apiURL}/agree21api/createGbrPerson`, {
      creditLimitAmount: creditLimitAmount,
      env: environment.name,
      _id: this.auth.user._id,
    });
    return response;
  }

  async postVollmacht(): Promise<Observable<any>> {
    return from(
      this.http
        .post(`${environment.apiURL}/agree21api/createVollmacht`, {
          _id: this.auth.user._id,
        })
        .toPromise()
    );
  }

  public girocardOnlineBankingForJurPerson(Personennummer, iban, wunschAlias): Observable<any> {
    return this.http.post(`${environment.apiURL}/agree21api/girocardOnlineBankingForJurPerson`, {
      Personennummer,
      iban,
      wunschAlias,
      _id: this.auth.user._id,
    });
  }

  public postGirocardOnlineBanking(Personennummer, iban, wunschAlias): Observable<any> {
    return this.http.post(`${environment.apiURL}/agree21api/girocardOnlineBanking`, {
      Personennummer,
      iban,
      wunschAlias,
      _id: this.auth.user._id,
    });
  }

  async postJurRollen(): Promise<Observable<any>> {
    return from(
      this.http
        .post(`${environment.apiURL}/agree21api/createPersonRollen`, {
          _id: this.auth.user._id,
        })
        .toPromise()
    );
  }
}
