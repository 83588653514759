<div *ngIf="deciedOffering && !waiting">
  <div class="card-container">
    <div>
      <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_KONTOVERBINDUNG'"></p>
    </div>
  </div>
  <div class="btn-container">
    <button
      data-cy="angebot_annehmen_oder_kontoverbindung_starten"
      mat-raised-button
      color="primary"
      (click)="nextstep()"
    >
      {{
        (kontoKorrentOrdered && kontoKorrentSuccess) || (creditCardOrdered && creditCardSuccess)
          ? 'Angebot annehmen'
          : 'Kontoverbindung starten'
      }}
      <mat-icon style="font-size: 1.3em">arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!deciedOffering">
  <div *ngIf="creditCheckStatus === 'success' && !angebotAccepted && getOffer">
    <div class="card-container">
      <P
        *ngIf="(kontoKorrentOrdered && kontoKorrentSuccess) || (creditCardOrdered && creditCardSuccess)"
        appTranslationModal
        [translate]="'FINAPI.ANGEBOT_TEXT'"
      ></P>
      <div *ngIf="creditCardOrdered && creditCardSuccess">
        <P><mat-icon class="check-icon">check</mat-icon>Kreditkartenlimit:&nbsp;{{ creditCardLimitAmount }}</P>
      </div>
      <div *ngIf="kontoKorrentOrdered && kontoKorrentSuccess">
        <P><mat-icon class="check-icon">check</mat-icon>Kreditlimit:&nbsp;{{ creditLimitAmount }} </P>
        <mat-icon class="check-icon">check</mat-icon>Sollzinssatz:
        <p style="display: inline-block" [translate]="'Zinsen.Sollzinssatz'">p.a.</p>
      </div>
      <div *ngIf="creditCardOrdered && kontoKorrentOrdered && !kontoKorrentSuccess && creditCardSuccess">
        <p>
          <mat-icon class="check-icon">close</mat-icon>Kreditlimit:&nbsp;<span
            appTranslationModal
            [translate]="'FINAPI.KREDITCHECK_KEIN_KREDITLIMIT'"
          ></span>
        </p>
      </div>
      <div *ngIf="creditCardOrdered && kontoKorrentOrdered && !kontoKorrentSuccess && !creditCardSuccess">
        <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_FAILED'"></p>
      </div>
      <div *ngIf="kontoKorrentOrdered && !creditCardOrdered && !kontoKorrentSuccess">
        <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_FAILED'"></p>
      </div>
      <div *ngIf="creditCardOrdered && !kontoKorrentOrdered && !creditCardSuccess">
        <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_FAILED'"></p>
      </div>
      <div *ngIf="!creditCardOrdered && !kontoKorrentOrdered">
        <p appTranslationModal [translate]="'FINAPI.KREDITCHECK_KONTOVERBINDUNG'"></p>
      </div>
    </div>
    <div class="btn-container">
      <button
        mat-stroked-button
        color="accent"
        (click)="openPopUp()"
        *ngIf="(kontoKorrentOrdered && kontoKorrentSuccess) || (creditCardOrdered && creditCardSuccess)"
      >
        Angebot ablehnen
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="nextstep()"
        data-cy="button_kreditangebot_annehmen_oder_konto_verbinden"
      >
        {{
          (kontoKorrentOrdered && kontoKorrentSuccess) || (creditCardOrdered && creditCardSuccess)
            ? 'Angebot annehmen'
            : 'Kontoverbindung starten'
        }}
        <mat-icon style="font-size: 1.3em">arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="creditCheckStatus === 'inprogress' && getOffer">
    <p class="text-center">Dieser Vorgang kann 2-3 Minuten dauern.</p>
    <div class="card-container text-center">
      <app-loader
        [text]="'FINAPI.KREDITCHECK_LOADER_TEXT'"
        [subText]="'FINAPI.KREDITCHECK_LOADER_SUBTEXT'"
      ></app-loader>
    </div>
    <div *ngIf="natPersonKontokorrenStatus === 'inprogress' && creditCheckStatus === 'success'">
      <p class="text-center">Dieser Vorgang kann 2-3 Minuten dauern.</p>
      <div class="card-container text-center">
        <app-loader [text]="'FINAPI.DOKUMENTE_LOADER_TEXT'" [subText]="'FINAPI.DOKUMENTE_LOADER_SUBTEXT'"></app-loader>
      </div>
    </div>

    <div class="card-container" *ngIf="creditCheckStatus === 'failed'">
      <p appTranslationModal [translate]="'FINAPI.KEINANGEBOT_TEXT'"></p>
    </div>
  </div>

  <div *ngIf="!getOffer">
    <p *ngIf="!finapiStop" class="text-center">Dieser Vorgang kann 2-3 Minuten dauern.</p>
    <div *ngIf="!finapiStop" class="card-container text-center">
      <app-loader
        [text]="'FINAPI.KREDITCHECK_LOADER_TEXT'"
        [subText]="'FINAPI.KREDITCHECK_LOADER_SUBTEXT'"
      ></app-loader>
    </div>

    <div class="card-container" *ngIf="creditCheckStatus === 'failed'">
      <p appTranslationModal [translate]="'FINAPI.KEINANGEBOT'"></p>
      <div class="btn-box">
        <button mat-stroked-button color="accent" (click)="openOne()">OK</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="waiting">
  <p class="text-center">Dieser Vorgang kann 2-3 Minuten dauern.</p>
  <div class="card-container text-center">
    <app-loader [text]="'FINAPI.DOKUMENTE_LOADER_TEXT'" [subText]="'FINAPI.DOKUMENTE_LOADER_SUBTEXT'"></app-loader>
  </div>
</div>
